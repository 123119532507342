<!--
  PACKAGE_NAME : src/pages/ui/components/esp-dx-tree-list-demo.vue
  FILE_NAME : esp-dx-tree-list-demo
  AUTHOR : hmlee
  DATE : 2024-11-22
  DESCRIPTION : 트리 데모 페이지
-->
<template>
  <div class="page-sub-box">
    <esp-dx-tree-list :tree-list="treeList" :ref="treeList.refName" />
  </div>
</template>

<script>
import EspDxTreeList from '@/components/devextreme/esp-dx-tree-list-v2.vue';

export default {
  components: {
    EspDxTreeList,
  },
  props: {},
  data() {
    return {
      treeList: {
        refName: 'codeRefName',
        dataSourceDefaultSortColumn: '+codeOrd,+codeNm', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
        sortKey: 'codeOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
        showColumnLines: true, // 컬럼 라인 표시 여부
        apiActionNm: {
          select: 'CODE_LIST_ALL',
          merge: 'CODE_LIST_MERGE',
          delete: 'CODE_LIST_DELETE',
        },
        showActionButtons: { // 그리드 버튼 노출 설정값
          delete: true, // 삭제 / false가 기본 / 버튼 노출시 멀티 셀렉션 모드로 변경
          sort: true, // 순서 저장 / true가 기본
          toggleExpand: true, // 목록 펼치기/접기 / true가 기본
          customButtons: [ // 커스텀 버튼 / []가 기본
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '커스텀',
                elementAttr: { class: 'btn_XS white light_filled' },
                height: 30,
                onClick: () => {
                  console.log('커스텀 버튼 클릭');
                },
              },
              location: 'before',
            },
          ],
        },
        //columnChooser: {}, //항목 선택 / false가 기본
        filterRow: { // 행 검색 필터 / true가 기본
          //visible: false,
        },
        //headerFilter: {}, // 헤더 필터 / false가 기본
        //KeyboardNavigation: {}, // 키보드 네비게이션 / false가 기본
        //loadPanel: {}, //로딩 패널 / true가 기본
        //remoteOperations: {}, // 서버사이드 설정 / false가 기본
        //rowDragging: {}, //드래깅 설정 / false가 기본
        //scrolling: {}, //스크롤 설정
        //searchPanel: {}, //검색 패널 / false가 기본
        //selection: {}, //선택 설정
        //sorting: {}, //정렬 설정
        columns: [
          {
            caption: '코드명',
            dataField: 'codeNm',
            alignment: 'left',
            allowHeaderFiltering: false,
            requiredRule: {},
          },
          {
            caption: '코드값',
            dataField: 'codeValue',
            allowHeaderFiltering: false,
            requiredRule: {},
          },
          {
            caption: '코드키',
            dataField: 'codeKey',
            allowHeaderFiltering: false,
            requiredRule: {},
          },
          {
            caption: '비고',
            dataField: 'description',
            allowHeaderFiltering: false,
          },
        ],
      },
    };
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
